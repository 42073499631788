<template>

  <div>

    <!-- <b-card>

      <h2 class="mb-2">
        Search Filter
      </h2>

      <b-row>

        <b-col
          cols="12"
          md="4"
          class="w-100"
        >
          <v-select
            v-if="filterProgramOptions.length > 0"
            v-model="filterDataProgram"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="filterProgramOptions"
            :clearable="false"
            :reduce="program => program.id"
            :placeholder="'Select Program'"
          />
          <v-select
            v-else
            :placeholder="'Select Program'"
          />
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <div class="d-flex align-items-center">
            <b-button
              class="ml-1"
              variant="primary"
              @click="clearFilters"
            >
              <span class="mr-25 align-middle">Reset filters</span>
            </b-button>
          </div>
        </b-col>
      </b-row>

    </b-card> -->

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="table-header m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="mr-1 mb-0">Show</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :value="perPage"
              :clearable="false"
              :reduce="option => option.value"
              class="per-page-selector d-inline-block ml-50 mr-1"
              @input="updatePerPage"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <label class="mr-1 mb-0">Search</label>
              <b-form-input
                v-model="searchQuery"
                placeholder="Search"
                class="w-25"
              />
              <b-button
                class="ml-1 w-25"
                variant="primary"
                :disabled="isLoading"
                @click="exportStaffCSV"
              >
                <b-spinner
                  v-if="isLoading"
                  small
                />
                <span
                  class="ml-25 align-middle"
                >Export Staff</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="fetchStaffList"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        sticky-header="75vh"
        empty-text="No matching records found"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative table-sticky-head"
      >
        <!-- Loader -->
        <template #table-busy>
          <TableSpinner />
        </template>

        <!-- Column: name -->
        <template #cell(name)="data">
          <b-avatar
            class="mr-1"
            size="32"
            :src="data.item.user.avatar"
          />
          <b-link
            :to="{ name: 'admin-staff-profile', params: { id: data.item.user.id } }"
          >{{ data.item.user.full_name }}</b-link>
        </template>

        <!-- Column: gender -->
        <template #cell(gender)="data">
          {{ data.item.user.extra_user_info.gender }}
        </template>

        <!-- Column: program -->
        <template #cell(recent_program)="data">
          <b-badge variant="light-secondary">
            {{ data.item.programs[0] ? data.item.programs[0].name : '' }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="mx-auto p-0"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              v-for="option in actionOptions"
              :key="option.value"
              :to="{ name: option.link, params: { id: data.item.user.id } }"
            >
              <span>{{ option.title }}</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('read', permissionSubjects.LoginAsStaff)"
              @click="loginAs(data.item.user.id, data.item.user.role.name)"
            >
              <span>Log In as Staff</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="$can('delete', permissionSubjects.Staff)"
              @click="deleteItem(data.item.user.id)"
            >
              <span>Delete Staff</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-if="totalItems && perPage !== 'all'"
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>

  </div>

</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination,
  BDropdown, BDropdownItem, BAvatar, BLink, BBadge, BButton, BSpinner,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import axios from '@axios'
import { mapGetters } from 'vuex'
import { downloadExportFile } from '@core/mixins/downloadExportFile'
import { loginAs } from '@core/mixins/loginAs'

import vSelect from 'vue-select'
import store from '@/store'
import storeModule from '@/views/admin/staff/staffStoreModule'
import useStaffList from '@/views/admin/staff/staff-list/useStaffList'
import useUsersList from '@/views/admin/users/users-list/useUsersList'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import { permissionSubjects } from '@/libs/acl/constants'
import { DEFAULT_PER_PAGE } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BLink,
    BBadge,
    BButton,
    BSpinner,

    vSelect,

    TableSpinner,
  },
  mixins: [downloadExportFile, loginAs],
  data() {
    return {
      schoolData: JSON.parse(localStorage.getItem('schoolData')),
      isLoading: false,
      permissionSubjects,
    }
  },
  setup(props, { root }) {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-staff'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchStaffList,
      tableColumns,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      searchQuery,
      sortBy,
      dataMeta,
      isSortDirDesc,
      refInvoiceListTable,

      actionOptions,

      refetchData,
      clearFilters,
    } = useStaffList(root)

    const { exportUsers } = useUsersList()

    return {
      fetchStaffList,
      exportUsers,
      tableColumns,
      perPage,
      perPageOptions,
      currentPage,
      totalItems,
      sortBy,
      searchQuery,
      dataMeta,
      isSortDirDesc,
      refInvoiceListTable,

      actionOptions,

      refetchData,
      clearFilters,
    }
  },
  computed: {
    ...mapGetters({
      programID: 'verticalMenu/getDefaultProgram',
    }),
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.refetchData()
    },
  },
  mounted() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    async deleteItem(ID) {
      await axios
        .delete(`auth/staffs/${ID}`)
        .then(() => {
          this.refetchData()
        })
        .catch(error => error)
    },
    async exportStaffCSV() {
      const queryParams = {
        sortBy: 'name',
        sortDesc: 0,
        role: 'staff',
        programId: this.programID,
      }
      this.isLoading = true

      await this.exportUsers(queryParams).then(response => {
        if (response.status === 200) {
          this.downloadFile(response.data, 'Staff', 'csv')
          this.isLoading = false
        }
      }).finally(() => {
        this.isLoading = false
      })
    },
    updatePerPage(val) {
      localStorage.setItem('staffPerPage', val)
      this.perPage = val
    },
    setFilterParams() {
      const query = { ...this.$route.query }
      const defaultPerPage = localStorage.getItem('staffPerPage')
      this.currentPage = Number(query.currentPage) || 1
      this.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
      this.searchQuery = query.searchQuery || ''
      this.filterDataLanguage = query.filterDataLanguage || ''
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     if (from.name === 'admin-program' && to.params.id) {
  //       // eslint-disable-next-line no-param-reassign
  //       vm.filterDataProgram = to.params.id
  //     }
  //   })
  // },
}
</script>

<style lang="scss" scoped>
  .table-header {

    label {
      margin-bottom: 0;
    }
  }

  .badge {
    text-transform: uppercase;
  }

  .per-page-selector {
    width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
